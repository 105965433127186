const CustomSelect = (function () {
	"use strict";
	//#region Private methods

	//#endregion

	return {
		//#region Public methods
		init: function () {
			$(".app-select").each((index, item) => {
				const _el = $(item);
				const placeholder = _el.data("placeholder");
				_el
					.select2({
						minimumResultsForSearch: Infinity,
						placeholder: placeholder || "",
					})
					.on("select2:select", (e) => {
						_el.addClass("app-select--selected");
					})
					.on("select2:unselect", (e) => {
						_el.removeClass("app-select--selected");
					});
			});
		},
		//#endregion
	};
})();

export default CustomSelect;
