import "./lib/vendor";
import CustomSelect from "./modules/CustomSelect";

$(document).ready(function () {
    CustomSelect.init();
});
function initializeIntlTelInput(inputId) {
    const phoneInputField = document.querySelector(`#${inputId}`);
    const phoneInput = window.intlTelInput(phoneInputField, {
        preferredCountries: ["it", "ro", "gr"],
        initialCountry: "auto",
        geoIpLookup: function (callback) {
            fetch('https://ipinfo.io/json', { headers: { 'Accept': 'application/json' } })
                .then((resp) => resp.json())
                .then((resp) => {
                    const countryCode = (resp && resp.country) ? resp.country : "us";
                    callback(countryCode);
                });
        },
        utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js"
    });

    phoneInputField.addEventListener("countrychange", function () {
        const countryData = phoneInput.getSelectedCountryData();
        const countryCode = '+' + countryData.dialCode;
        phoneInputField.value = countryCode;
    });
}
initializeIntlTelInput("phone");
initializeIntlTelInput("phone2");
var radioButtons = document.querySelectorAll('input[type="radio"]');
radioButtons.forEach(function (radioButton) {
    radioButton.addEventListener('click', function () {
        var isAlreadySelected = this.closest('label').classList.contains('selected');
        radioButtons.forEach(function (btn) {
            btn.closest('label').classList.remove('selected');
        });
        if (!isAlreadySelected) {
            this.closest('label').classList.add('selected');
        }
    });
});
var telInputs = document.querySelectorAll('input[type="tel"]');
telInputs.forEach(function(input) {
    input.addEventListener('input', function() {
        input.value = input.value.replace(/[^0-9+]/g, '');
    });
});
const supportedLanguages = ['ro', 'gr', 'it'];
const defaultLanguage = '';

function getPreferredLanguage() {
    const userLanguages = navigator.languages;

    for (let lang of userLanguages) {
        const languageCode = lang.slice(0, 2);
        if (supportedLanguages.includes(languageCode)) {
            return languageCode;
        }
    }

    return defaultLanguage;
}

function redirectToPreferredLanguage() {
    const preferredLanguage = getPreferredLanguage();
    const currentPath = window.location.pathname;

    const pathSegments = currentPath.split('/');
    if (pathSegments.length > 1 && supportedLanguages.concat(defaultLanguage).includes(pathSegments[1])) {
        return;
    }

    const newPath = `/${preferredLanguage}${currentPath}`;
    window.location.href = newPath;
}

window.onload = redirectToPreferredLanguage;


const stepsBlock = document.querySelector("[steps-block]");
const listStepsNumbers = stepsBlock.querySelectorAll(".number");
const btnsNext = document.querySelectorAll(".btnnext");
const btnsBack = document.querySelectorAll(".btnback");
const quizList = document.querySelectorAll(".quiz");

btnsNext.forEach(btn => {
    btn.addEventListener("click", handleBtnNextClick);
});

btnsBack.forEach(btn => {
    btn.addEventListener("click", handleBtnBackClick);
});

function handleBtnNextClick(event) {
    const btnIndex = Array.from(btnsNext).indexOf(event.target) + 1;
    let currentBlock = document.querySelector(`.block${btnIndex}`);
    let nextBlock = document.querySelector(`.block${btnIndex + 1}`);
    let question = currentBlock.querySelector('.title');
    let activeRadioButton = currentBlock.querySelector('input[type="radio"]:checked');

    if (activeRadioButton && question && btnIndex != 9) {
        let answer = activeRadioButton.parentElement.querySelector('.text').textContent;
        if (answer) {
            setTestQuestionAndAnswer(btnIndex, question.textContent, answer);
            currentBlock.classList.add("hide");
            nextBlock.classList.remove("hide");
            listStepsNumbers[btnIndex - 1].classList.add("done");
            listStepsNumbers[btnIndex].classList.add("active");

            if (btnIndex == 3) {
                localStorage.setItem("bodyType", activeRadioButton.value);
            }

            if (btnIndex == 8) {
                localStorage.setItem("age", getFirstPartOfString(answer));
            }
        }
    }

    if(isScreenBelow769()){

        if(btnIndex == 1||btnIndex == 9){
           var isValid = validateСontactInputs(currentBlock);
           if (!isValid) {
            return;
        }
        }

        switch(btnIndex){
            case 1:
                listStepsNumbers[3].classList.add("mobblock");
                break;
            case 3:
                listStepsNumbers[4].classList.add("mobblock");
                listStepsNumbers[2].classList.add('mobnone');
                break;
            case 4:
                listStepsNumbers[5].classList.add("mobblock");
                listStepsNumbers[3].classList.add('mobnone');
                break;
            case 5:
                listStepsNumbers[6].classList.add("mobblock");
                listStepsNumbers[4].classList.add('mobnone');
                break;
            case 6:
                listStepsNumbers[7].classList.add("mobblock");
                listStepsNumbers[5].classList.add('mobnone');
                break;
            case 7:
                listStepsNumbers[8].classList.add("mobblock");
                break;
            default:
                break;
        }
}
    

    if (btnIndex == 8) {
        insertDatatoContactForm(nextBlock);
    }

    if ((btnIndex == 1 && validateСontactInputs(currentBlock) || btnIndex == 9 && validateСontactInputs(currentBlock) && validateCheckRadio())) {
        currentBlock.classList.add("hide");
        nextBlock.classList.remove("hide");
        listStepsNumbers[btnIndex - 1].classList.add("done");
        listStepsNumbers[btnIndex].classList.add("active");
        
        if (btnIndex == 9) {
            let age = localStorage.getItem("age");
            let body = localStorage.getItem("bodyType");
            if (age && body) {
                let blockClassName = `result${age}_${body}`;
                var elements = document.getElementsByClassName(blockClassName);
                if (elements.length > 0) {
                    var firstElement = elements[0];
                    firstElement.classList.remove("hide");
                    submitForm();
                }
            }
        }
    }
}

function isScreenBelow769() {
    var screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    if (screenWidth < 769) {
        return true;
    } else {
        return false;
    }
}

function submitForm() {
    var form = document.forms[0];
    var formData = new FormData(form);

    var userComment = '';
    for (var i = 1; i <= 8; i++) {
        if (i === 4 || i === 5 || i === 6 || i === 7) {
        continue;
    }
        var testKey = 'test' + i;
        var testData = localStorage.getItem(testKey);
        
        if (testData) {
            var testObject = JSON.parse(testData);
            userComment += `${i}: ${testObject.question} - ${testObject.answer}\n`;
        }
    }

    formData.append('user_comment', userComment);

    fetch('/api.php', {
        method: 'POST',
        body: formData
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Произошла ошибка при отправке формы');
        }
        return response.json(); 
    })
    .then(data => {
        console.log('Форма успешно отправлена');
        console.log(data); // Ответ от сервера
    })
    .catch(error => {
        console.error('Ошибка:', error);
    });
}

function handleBtnBackClick(event) {
    const btnIndex = Array.from(btnsBack).indexOf(event.target);
    let currentBlock = document.querySelector(`.block${btnIndex + 2}`);
    let previousBlock = document.querySelector(`.block${btnIndex + 1}`);
    currentBlock.classList.add("hide");
    previousBlock.classList.remove("hide");
    listStepsNumbers[btnIndex].classList.remove("done");
    listStepsNumbers[btnIndex + 1].classList.remove("active");
}

window.addEventListener('load', function () {
    var nameResult = localStorage.getItem("name");
    for (var i = 1; i <= 10; i++) {
        var testKey = 'test' + i;
        var testResult = localStorage.getItem(testKey);
        if (testResult || !testResult && testKey == "test1" && nameResult) {
            quizList[i - 1].classList.add("hide");
            quizList[i].classList.remove("hide");
            listStepsNumbers[i].classList.add("active");
            listStepsNumbers[i - 1].classList.add("done");
        }
    }

    if (!nameResult) {
        listStepsNumbers[0].classList.add("active");
        quizList[0].classList.remove("hide");
    } else {
        listStepsNumbers[0].classList.add("done");
    }

    insertDatatoContactForm(quizList[8]);
});

function getFirstPartOfString(str) {
    return str.split(/[-+]/)[0].trim();
}

function setTestQuestionAndAnswer(testNumber, question, answer) {
    var testKey = 'test' + testNumber;
    var testObject = {
        question: question,
        answer: answer
    };

    localStorage.setItem(testKey, JSON.stringify(testObject));
}

document.addEventListener('DOMContentLoaded', function () {
    const languageBlock = document.querySelector('.language');
    const languageList = document.querySelector('.language__list');

    languageBlock.addEventListener('click', function () {
        languageBlock.classList.toggle('active');
        languageList.classList.toggle('active');
    });
});


function validateСontactInputs(inputsContainer) {
    const nameInput = inputsContainer.querySelector('input[name="name"]');
    const emailInput = inputsContainer.querySelector('input[name="email"]');
    const phoneInput = inputsContainer.querySelector('input[name="phone"]');
    const selectElement = inputsContainer.querySelector('select[name="country"]');

    let isValid = true;

    if (nameInput.value.trim() === '') {
        nameInput.classList.add("error");
        const nameError = nameInput.closest('.input-wrap').querySelector('.error_text');
        if (nameError) {
            nameError.classList.add('active');
        }
        isValid = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(emailInput.value)) {
        emailInput.classList.add("error");
        const emailError = emailInput.closest('.input-wrap').querySelector('.error_text');
        if (emailError) {
            emailError.classList.add('active');
        }
        isValid = false;
    }

    const phoneRegex = /^\+?\d{10,15}$/;
    if (!phoneRegex.test(phoneInput.value)) {
        phoneInput.classList.add("error");
        const phoneError = phoneInput.closest('.input-wrap').querySelector('.error_text');
        if (phoneError) {
            phoneError.classList.add('active');
        }
        isValid = false;
    }

    if (!selectElement.value) {
        selectElement.classList.add("error");
        const countryError = selectElement.closest('.select').nextElementSibling;
        if (countryError && countryError.classList.contains('error_text')) {
            countryError.classList.add('active');
        }
        isValid = false;
    }

    if (isValid) {
        localStorage.setItem('name', nameInput.value);
        localStorage.setItem('email', emailInput.value);
        localStorage.setItem('phone', phoneInput.value);
        localStorage.setItem('country', selectElement.value);
    }

    return isValid;
}


function insertDatatoContactForm(inputsContainer) {
    const nameInput = inputsContainer.querySelector('input[name="name"]');
    const emailInput = inputsContainer.querySelector('input[name="email"]');
    const phoneInput = inputsContainer.querySelector('input[name="phone"]');
    const selectElement = inputsContainer.querySelector('select[name="country"]');
    const selectElementText = inputsContainer.querySelector('.select2-selection__placeholder');

    const name = localStorage.getItem("name");
    if (name) {
        nameInput.value = name;
    }
    const email = localStorage.getItem("email");
    if (email) {
        emailInput.value = email;
    }
    const phone = localStorage.getItem("phone");
    if (phone) {
        phoneInput.value = phone;
    }
    const country = localStorage.getItem("country");
    if (country) {
        selectElement.value = country;
        if (selectElementText) {
            selectElementText.textContent = country;
        }
    }
}

function validateCheckRadio() {
    const radioButtons = document.querySelectorAll('input[name="check"]');
    let isChecked = false;
    const errorText = document.querySelector('.confirmation .error_text');

    radioButtons.forEach((radioButton) => {
        if (radioButton.checked) {
            isChecked = true;
        }
    });

    if (!isChecked) {
        errorText.classList.add('active');
        return false;
    }

    if (errorText.classList.contains('active')) {
        errorText.classList.remove('active');
    }

    return true;
}


document.addEventListener('DOMContentLoaded', () => {
    const inputSets = document.querySelectorAll('.form_block');

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\+?\d{10,15}$/;

    inputSets.forEach(inputsContainer => {
        const nameInput = inputsContainer.querySelector('input[name="name"]');
        const emailInput = inputsContainer.querySelector('input[name="email"]');
        const phoneInput = inputsContainer.querySelector('input[name="phone"]');
        const selectElement = inputsContainer.querySelector('select[name="country"]');

        if (nameInput) {
            nameInput.addEventListener('input', () => {
                if (nameInput.value.trim() !== '') {
                    nameInput.classList.remove('error');
                    const nameError = nameInput.closest('.input-wrap').querySelector('.error_text');
                    if (nameError) {
                        nameError.classList.remove('active');
                    }
                }
            });
        }

        if (emailInput) {
            emailInput.addEventListener('input', () => {
                if (emailRegex.test(emailInput.value)) {
                    emailInput.classList.remove('error');
                    const emailError = emailInput.closest('.input-wrap').querySelector('.error_text');
                    if (emailError) {
                        emailError.classList.remove('active');
                    }
                }
            });
        }

        if (phoneInput) {
            phoneInput.addEventListener('input', () => {
                if (phoneRegex.test(phoneInput.value)) {
                    phoneInput.classList.remove('error');
                    const phoneError = phoneInput.closest('.input-wrap').querySelector('.error_text');
                    if (phoneError) {
                        phoneError.classList.remove('active');
                    }
                }
            });
        }

        if (selectElement) {
            selectElement.addEventListener('change', () => {
                if (selectElement.value) {
                    selectElement.classList.remove('error');
                    const countryError = selectElement.closest('.select').nextElementSibling;
                    if (countryError && countryError.classList.contains('error_text')) {
                        countryError.classList.remove('active');
                    }
                }
            });
        }
    });
});

